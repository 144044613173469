import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

// componenets desktop View
import Work from "./components/Index";
import About from "./components/about";
import Bod from "./components/bod";
import Kp from "./components/key_personell";
import Process from "./components/process";
import Service from "./components/services";
import Serviced from "./components/services-d";
import Projects from "./components/projects";
import Contact from "./components/contact";
import Admin from "./components/admin";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Work></Work>} status={404} />
          <Route path="/" element={<Work></Work>} />
          <Route path="/about" element={<About></About>} />
          <Route path="/bod" element={<Bod></Bod>} />
          <Route path="/kp" element={<Kp></Kp>} />
          <Route path="/ic" element={<Process></Process>} />
          <Route path="/wws" element={<Service></Service>} />
          <Route path="/service-d" element={<Serviced></Serviced>} />
          <Route path="/projects" element={<Projects></Projects>} />
          <Route path="/contact" element={<Contact></Contact>} />
          <Route path="/admin" element={<Admin></Admin>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
