import React from "react";

import Nav from "./nav";

import Footer from "./footer";

function View() {
  return (
    <>
      <Nav page={"about"}></Nav>

      <main id="main">
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{ backgroundImage: 'url("img/breadcrumbs-bg.jpg")' }}
        >
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h2>About Us</h2>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>About Us</li>
            </ol>
          </div>
        </div>

        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="row position-relative px-lg-5 mx-lg-3">
              <div
                className="col-lg-7 about-img"
                style={{ backgroundImage: "url(img/about.jpg)" }}
              ></div>

              <div className="col-lg-7">
                <h2>Our Journey Begins</h2>
                <div className="our-story">
                  <h4>Est 2023</h4>
                  <h3>Our Story</h3>
                  <p>
                    Ganga Technocast embarked on its inspiring journey with a
                    vision to revolutionize the casting industry. Our company is
                    built on the pillars of innovation, dedication, and
                    relentless pursuit of excellence. Though we are a new
                    entrant, we are fueled by a drive to redefine precision
                    casting solutions and make a significant impact in the
                    market.
                  </p>
                  <p>
                    With a commitment to delivering impeccable quality and
                    fostering lasting partnerships, Ganga Technocast aims to be
                    the go-to casting provider for industries worldwide. Our
                    dynamic team thrives on challenges, and we embrace each
                    project with enthusiasm, bringing fresh perspectives and
                    creative solutions to the table.
                  </p>
                  <p>
                    Join us on this exciting journey as we forge ahead, writing
                    a new chapter in the world of precision casting. At Ganga
                    Technocast, the future is full of endless possibilities, and
                    we look forward to shaping it together with you.
                  </p>

                  {/* <!-- UC: Video --> */}
                  {/* <!-- <div className="watch-video d-flex align-items-center position-relative">
                <i className="bi bi-play-circle"></i>
                <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox stretched-link">Watch Video</a>
              </div> --> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="stats-counter" className="stats-counter section-bg">
          <div className="container">
            <div className="row gy-4 px-lg-5 mx-lg-3">
              <div className="col-lg-3 col-md-6">
                <div className="stats-item d-flex align-items-center w-100 h-100">
                  <i className="bi bi-emoji-smile color-blue flex-shrink-0"></i>
                  <div>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="30"
                      data-purecounter-duration="1"
                      className="purecounter"
                    ></span>
                    <p>Happy Clients</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="stats-item d-flex align-items-center w-100 h-100">
                  <i className="bi bi-journal-richtext color-orange flex-shrink-0"></i>
                  <div>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="20"
                      data-purecounter-duration="1"
                      className="purecounter"
                    ></span>
                    <p>Projects</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="stats-item d-flex align-items-center w-100 h-100">
                  <i className="bi bi-headset color-green flex-shrink-0"></i>
                  <div>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="200"
                      data-purecounter-duration="1"
                      className="purecounter"
                    ></span>
                    <p>Hours Of Support</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="stats-item d-flex align-items-center w-100 h-100">
                  <i className="bi bi-people color-pink flex-shrink-0"></i>
                  <div>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="30"
                      data-purecounter-duration="1"
                      className="purecounter"
                    ></span>
                    <p>Hard Workers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="alt-services" className="alt-services">
          <div className="container" data-aos="fade-up">
            <div className="row justify-content-around gy-4 px-lg-5 mx-lg-3">
              <div
                className="col-lg-6 img-bg"
                style={{ backgroundImage: "url(img/alt-services.jpg)" }}
                data-aos="zoom-in"
                data-aos-delay="100"
              ></div>

              <div className="col-lg-5 d-flex flex-column justify-content-center">
                <h3>Innovation at the Core</h3>
                <p>
                  At Ganga Technocast, innovation lies at the heart of
                  everything we do. We continually push the boundaries of
                  casting technology to provide cutting-edge solutions to our
                  clients. Our dynamic and skilled team is committed to
                  embracing innovation in every aspect of the casting process,
                  ensuring that we deliver superior results with efficiency and
                  precision.
                </p>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i className="bi bi-gem flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>Precision Engineering</a>
                    </h4>
                    <p>
                      Our meticulous attention to detail and precision
                      engineering techniques enable us to produce complex
                      castings with exceptional accuracy, meeting the most
                      demanding specifications and industry standards.
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i className="bi bi-gear flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>Advanced Technology</a>
                    </h4>
                    <p>
                      We leverage the latest advancements in casting technology
                      to optimize our production processes, ensuring
                      cost-effectiveness, reduced lead times, and consistent
                      high-quality results.
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i className="bi bi-hammer flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>Customization & Flexibility</a>
                    </h4>
                    <p>
                      Embracing innovation allows us to offer flexible casting
                      solutions tailored to each client's unique requirements.
                      Whether it's rapid prototyping or large-scale production,
                      we adapt our approach to suit your needs.
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i className="bi bi-check-circle flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>Quality Assurance</a>
                    </h4>
                    <p>
                      Our dedication to innovation extends to our quality
                      assurance processes. With state-of-the-art inspection
                      methods, we maintain stringent quality control at every
                      stage, ensuring flawless castings that exceed
                      expectations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="alt-services-2" className="alt-services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="row justify-content-around gy-4 px-lg-5 mx-lg-3">
              <div className="col-lg-5 d-flex flex-column justify-content-center">
                <h3>Elevating Casting Solutions</h3>
                <p>
                  Ganga Technocast stands at the forefront of innovation in the
                  casting industry. Our forward-thinking approach and unwavering
                  commitment to excellence drive us to deliver casting solutions
                  that surpass industry standards and customer expectations.
                </p>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i className="bi bi-lightning-charge flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>Rapid Prototyping</a>
                    </h4>
                    <p>
                      Harnessing the power of rapid prototyping, we transform
                      ideas into tangible prototypes with unmatched speed,
                      enabling you to validate and refine your designs
                      efficiently.
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i className="bi bi-tools flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>State-of-the-Art Equipment</a>
                    </h4>
                    <p>
                      Our state-of-the-art casting equipment empowers us to
                      execute intricate designs with precision and achieve
                      consistent quality across all projects.
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i className="bi bi-cpu flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>Simulation & Analysis</a>
                    </h4>
                    <p>
                      Utilizing advanced simulation tools, we conduct in-depth
                      analysis to optimize casting processes, ensuring efficient
                      material usage and reduced production time.
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i className="bi bi-brightness-high flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>Global Partnerships</a>
                    </h4>
                    <p>
                      Embracing innovation has enabled us to establish global
                      partnerships, collaborating with industry leaders to
                      explore cutting-edge technologies and expand our casting
                      horizons.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 img-bg"
                style={{
                  backgroundImage: "url(img/alt-services-2.jpg)",
                }}
                data-aos="zoom-in"
                data-aos-delay="100"
              ></div>
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>

      <div id="preloader" hidden></div>
    </>
  );
}

export default View;
