import React from "react";

function View() {
  return (
    <footer id="footer" className="footer">
      <div className="footer-content position-relative">
        <div className="container">
          <div className="row px-lg-5 mx-lg-3">
            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <img src="/img/logo.png" className="mb-3" alt="" width="50%" />
                <p>
                  Factory Address: <br />
                  Survey No: 61/P3, <br />
                  Village: Bharudi, Tal: Gondal, <br />
                  Rajkot, Gujarat 360311, India
                </p>
                <p>
                  Corporate Office Address: <br />
                  501, Meghmalhar Appt, <br />
                  B/h. Panchvati Main Road, <br />
                  Rajkot, Gujarat 360001, India
                </p>

                <p>
                  <br />
                  <br />
                  <strong>Phone:</strong> <br />
                  +91 9426346460
                  <br />
                  <strong>Email:</strong> <br />
                  <a href="mailto:info@gangatechnocast.co.in">
                    info@gangatechnocast.co.in
                  </a>
                  <br />
                </p>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/about">About us</a>
                </li>
                <li>
                  <a href="/wws">Services</a>
                </li>
                <li>
                  <a href="/bod">Board of Directors</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-legal text-center position-relative">
        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>UpConstruction</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            {/* <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/upconstruction-bootstrap-construction-website-template/ -->
        <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> --> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default View;
