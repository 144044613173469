import React from "react";

import Nav from "./nav";

import Footer from "./footer";

function View() {
  return (
    <>
      <Nav page={"ic"}></Nav>

      <main id="main">
        {/* <!-- ======= Breadcrumbs ======= --> */}
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{ backgroundImage: ' url("img/breadcrumbs-bg.jpg")' }}
        >
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h2>Investment Casting</h2>
            <ol>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Investment Casting</li>
            </ol>

            <div className="watch-video d-flex align-items-center position-relative">
              <a href="video/demo.mp4" className="glightbox stretched-link">
                <i
                  className="bi bi-play-circle"
                  style={{ fontSize: "40px" }}
                ></i>
              </a>
            </div>
          </div>
        </div>
        {/* <!-- End Breadcrumbs --> */}

        {/* <!-- ======= Process Section ======= --> */}
        <section id="process" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Process</h2>
              <p>Investment Casting Process</p>
            </div>

            <div className="row gy-4 px-lg-5 mx-lg-3">
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-pencil-square"></i>
                  </div>
                  <h3>Wax Pattern Creation</h3>
                  <p>
                    The process commences with creating intricate wax patterns,
                    replicating the final shape of the desired component with
                    precision and attention to detail.
                  </p>
                  {/* <!-- UC: read more line --> */}
                  {/* <!-- <a href="service-details.html" className="readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></a> --> */}
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-gear"></i>
                  </div>
                  <h3>Assembly & Gating</h3>
                  <p>
                    The wax patterns are assembled onto a gating system, which
                    facilitates the flow of molten metal during the casting
                    process.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-paint-bucket"></i>
                  </div>
                  <h3>Ceramic Shell Coating</h3>
                  <p>
                    The assembly is coated with multiple layers of ceramic
                    shells, forming a refractory mold that can withstand high
                    temperatures.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-thermometer-half"></i>
                  </div>
                  <h3>Dewaxing & Preheating</h3>
                  <p>
                    The ceramic mold is heated to remove the wax, leaving behind
                    a cavity for the molten metal. The mold is then preheated to
                    prepare for casting.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-droplet-half"></i>
                  </div>
                  <h3>Casting</h3>
                  <p>
                    Molten metal is poured into the preheated mold, filling the
                    cavity and taking the shape of the original wax pattern.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-snow2"></i>
                  </div>
                  <h3>Cooling & Solidification</h3>
                  <p>
                    The casting is allowed to cool and solidify, ensuring the
                    metal attains the desired mechanical properties and shape.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-brush"></i>
                  </div>
                  <h3>Shell Removal</h3>
                  <p>
                    The ceramic shell is removed to unveil the final casting,
                    revealing the intricate details and accuracy of the
                    component.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-tools"></i>
                  </div>
                  <h3>Finishing & Inspection</h3>
                  <p>
                    The castings undergo finishing processes like grinding,
                    machining, and polishing to achieve the required surface
                    finish and dimensional accuracy. Thorough inspection ensures
                    quality assurance.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-box"></i>
                  </div>
                  <h3>Ready for Delivery</h3>
                  <p>
                    The finished investment castings are now ready for delivery,
                    meeting the highest standards and customer specifications.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>
          </div>
        </section>
        {/* <!-- End Process Section --> */}

        {/* <!-- ======= Material Section ======= --> */}
        <section id="Materials" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Material</h2>
              <p>List of Materials we use.</p>
            </div>

            <div id="pt" className="row px-lg-5 mx-lg-3">
              <div className="col">
                <div className="card" style={{ border: "transparent" }}>
                  <div className="card-body" style={{ overflowX: "scroll" }}>
                    <h5>Material Properties Table</h5>
                    <p>
                      <i>
                        Provides an overview of key properties for each
                        material.
                      </i>
                    </p>
                    <br />
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Material</th>
                          <th>Description</th>
                          <th>Density (g/cm³)</th>
                          <th>Tensile Strength (MPa)</th>
                          <th>Hardness (HRC)</th>
                          <th>Thermal Conductivity (W/m·K)</th>
                          <th>Melting Point (°C)</th>
                          <th>Corrosion Resistance</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Stainless Steel</td>
                          <td>Corrosion-resistant steel alloy</td>
                          <td>7.8</td>
                          <td>600-900</td>
                          <td>15-30</td>
                          <td>16.3</td>
                          <td>1400-1450</td>
                          <td>High</td>
                        </tr>
                        <tr>
                          <td>Carbon Steel</td>
                          <td>Low-cost steel alloy</td>
                          <td>7.85</td>
                          <td>370-520</td>
                          <td>20-50</td>
                          <td>50.2</td>
                          <td>1425</td>
                          <td>Moderate</td>
                        </tr>
                        <tr>
                          <td>Aluminum Alloy</td>
                          <td>Lightweight and corrosion-resistant</td>
                          <td>2.7</td>
                          <td>215-310</td>
                          <td>30-75</td>
                          <td>205</td>
                          <td>660</td>
                          <td>High</td>
                        </tr>
                        {/* <!-- Add more rows for additional materials --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>
            <div id="st" className="row px-lg-5 mx-lg-3 mt-2">
              <div className="col">
                <div className="card" style={{ border: "transparent" }}>
                  <div className="card-body" style={{ overflowX: "scroll" }}>
                    <h5>Material Specifications Table</h5>
                    <p>
                      <i>
                        Lists specifications and standards for each material.
                      </i>
                    </p>
                    <br />
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Material Name</th>
                          <th>ASTM Standard</th>
                          <th>Composition (Chemical)</th>
                          <th>Mechanical Properties</th>
                          <th>Heat Treatment</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Stainless Steel</td>
                          <td>ASTM A743/A743M</td>
                          <td>Iron, Chromium, Nickel</td>
                          <td>
                            Tensile Strength: 600-900 MPa
                            <br />
                            Hardness: 15-30 HRC
                          </td>
                          <td>Quenching and Tempering</td>
                        </tr>
                        <tr>
                          <td>Carbon Steel</td>
                          <td>ASTM A958/A958M</td>
                          <td>Iron, Carbon, Manganese, Silicon</td>
                          <td>
                            Tensile Strength: 370-520 MPa
                            <br />
                            Hardness: 20-50 HRC
                          </td>
                          <td>Normalizing</td>
                        </tr>
                        <tr>
                          <td>Aluminum Alloy</td>
                          <td>ASTM B26/B26M</td>
                          <td>Aluminum, Copper, Silicon, Magnesium</td>
                          <td>
                            Tensile Strength: 215-310 MPa
                            <br />
                            Hardness: 30-75 HRC
                          </td>
                          <td>Solution Heat Treatment</td>
                        </tr>
                        {/* <!-- Add more rows for additional materials --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>
            <div id="ct" className="row px-lg-5 mx-lg-3 mt-2">
              <div className="col">
                <div className="card" style={{ border: "transparent" }}>
                  <div className="card-body" style={{ overflowX: "scroll" }}>
                    <h5>Material Compatibility Table</h5>
                    <p>
                      <i>
                        Provides information on how well the material interacts
                        with other
                      </i>
                    </p>
                    <br />
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Material Name</th>
                          <th>Compatibility with Other Materials</th>
                          <th>Common Applications</th>
                          <th>Usage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Stainless Steel</td>
                          <td>
                            Compatible with most metals and alloys. Good
                            resistance to corrosion.
                          </td>
                          <td>
                            Industrial machinery, medical instruments, kitchen
                            appliances
                          </td>
                          <td>
                            Commonly used in diverse applications due to its
                            corrosion resistance.
                          </td>
                        </tr>
                        <tr>
                          <td>Carbon Steel</td>
                          <td>
                            May corrode in the presence of moisture and certain
                            chemicals.
                          </td>
                          <td>
                            Structural components, automotive parts,
                            construction materials
                          </td>
                          <td>
                            Widely used in construction and manufacturing but
                            may require protective coatings.
                          </td>
                        </tr>
                        <tr>
                          <td>Aluminum Alloy</td>
                          <td>
                            May corrode in the presence of certain chemicals.
                            Not suitable for contact with strong acids.
                          </td>
                          <td>
                            Aircraft components, automotive parts, consumer
                            electronics
                          </td>
                          <td>
                            Lightweight and commonly used in aerospace and
                            automotive industries.
                          </td>
                        </tr>
                        {/* <!-- Add more rows for additional materials --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>

            <div id="cct" className="row px-lg-5 mx-lg-3 mt-2">
              <div className="col">
                <div className="card" style={{ border: "transparent" }}>
                  <div className="card-body" style={{ overflowX: "scroll" }}>
                    <h5>Material Cost Comparison Table</h5>
                    <p>
                      <i>Helps compare the costs of different materials. </i>
                    </p>
                    <br />
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Material Name</th>
                          <th>Cost per Unit</th>
                          <th>Notes on Cost Factors</th>
                          <th>Usage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Stainless Steel</td>
                          <td>$X per pound</td>
                          <td>
                            Cost may vary based on grade, finish, and quantity.
                          </td>
                          <td>
                            Commonly used in applications requiring corrosion
                            resistance.
                          </td>
                        </tr>
                        <tr>
                          <td>Carbon Steel</td>
                          <td>$Y per pound</td>
                          <td>
                            Relatively lower cost compared to stainless steel.
                          </td>
                          <td>
                            Used in structural and industrial applications.
                          </td>
                        </tr>
                        <tr>
                          <td>Aluminum Alloy</td>
                          <td>$Z per pound</td>
                          <td>Higher cost due to processing and alloying.</td>
                          <td>
                            Preferred for lightweight applications in aerospace
                            and automotive.
                          </td>
                        </tr>
                        {/* <!-- Add more rows for additional materials --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>

            <div id="sst" className="row px-lg-5 mx-lg-3 mt-2">
              <div className="col">
                <div className="card" style={{ border: "transparent" }}>
                  <div className="card-body" style={{ overflowX: "scroll" }}>
                    <h5>Material Sustainability Table</h5>
                    <p>
                      <i>
                        Highlights the sustainability aspects of each material.
                      </i>
                    </p>
                    <br />
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Material Name</th>
                          <th>Environmental Impact</th>
                          <th>Recyclability</th>
                          <th>Renewable Sources</th>
                          <th>Usage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Stainless Steel</td>
                          <td>Low environmental impact. Can be recycled.</td>
                          <td>High recyclability</td>
                          <td>No</td>
                          <td>
                            Widely used in applications requiring durability and
                            corrosion resistance.
                          </td>
                        </tr>
                        <tr>
                          <td>Carbon Steel</td>
                          <td>
                            Moderate environmental impact. Highly recyclable.
                          </td>
                          <td>High recyclability</td>
                          <td>No</td>
                          <td>
                            Commonly used in construction and manufacturing.
                          </td>
                        </tr>
                        <tr>
                          <td>Aluminum Alloy</td>
                          <td>
                            Moderate environmental impact. Highly recyclable.
                          </td>
                          <td>High recyclability</td>
                          <td>Yes (aluminum is derived from bauxite ore)</td>
                          <td>
                            Preferred for lightweight applications in aerospace
                            and automotive.
                          </td>
                        </tr>
                        {/* <!-- Add more rows for additional materials --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>
            <div id="at" className="row px-lg-5 mx-lg-3 mt-2">
              <div className="col">
                <div className="card" style={{ border: "transparent" }}>
                  <div className="card-body" style={{ overflowX: "scroll" }}>
                    <h5>Material Applications Table</h5>
                    <p>
                      <i>Describes where each material is commonly applied.</i>
                    </p>
                    <br />
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Material Name</th>
                          <th>Common Applications</th>
                          <th>Industries</th>
                          <th>Use Cases</th>
                          <th>Usage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Stainless Steel</td>
                          <td>
                            Kitchen appliances, cutlery, industrial machinery
                          </td>
                          <td>Manufacturing, Construction, Aerospace</td>
                          <td>
                            Food processing, structural components, aerospace
                            components
                          </td>
                          <td>
                            Widely used for its corrosion resistance and
                            durability.
                          </td>
                        </tr>
                        <tr>
                          <td>Carbon Steel</td>
                          <td>
                            Structural components, automotive parts, machinery
                          </td>
                          <td>Construction, Automotive, Manufacturing</td>
                          <td>
                            Building structures, automotive frames, industrial
                            machinery
                          </td>
                          <td>
                            Commonly used for its strength and
                            cost-effectiveness.
                          </td>
                        </tr>
                        <tr>
                          <td>Aluminum Alloy</td>
                          <td>
                            Aircraft components, automotive parts, consumer
                            electronics
                          </td>
                          <td>Aerospace, Automotive, Electronics</td>
                          <td>
                            Aircraft frames, automotive body panels, electronic
                            enclosures
                          </td>
                          <td>
                            Preferred for lightweight applications in various
                            industries.
                          </td>
                        </tr>
                        {/* <!-- Add more rows for additional materials --> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>
          </div>
        </section>
        {/* <!-- End Material Section --> */}
      </main>

      <Footer></Footer>

      <div id="preloader" hidden></div>
    </>
  );
}

export default View;
