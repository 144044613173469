import React from "react";

function View({ page }) {
  return (
    <header id="header" className="header d-flex align-items-center">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between px-lg-5 mx-lg-5">
        <a href="/" className="logo d-flex align-items-center">
          <img src="/img/logo.png" alt="" />
        </a>

        <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a href="/" className={page === "home" ? "active" : ""}>
                Home
              </a>
            </li>
            <li className="dropdown">
              <a
                href="/about"
                className={
                  page === "about" || page === "kp" || page === "bod"
                    ? "active"
                    : ""
                }
              >
                About
              </a>
              <ul>
                <li>
                  <a href="/about">About us</a>
                </li>
                <li>
                  <a href="/bod">Board of Directors</a>
                </li>
                {/* <li>
                  <a href="/kp">Key Personnel</a>
                </li> */}
              </ul>
            </li>

            <li className="dropdown">
              <a href="/ic" className={page === "ic" ? "active" : ""}>
                Process
              </a>
              <ul>
                <li>
                  <a href="/ic#process">Process</a>
                </li>
                <li className="dropdown">
                  <a href="/ic#Materials">Materials</a>
                  <ul>
                    <li>
                      <a href="/ic#pt">Properties Table</a>
                    </li>
                    <li>
                      <a href="/ic#st">Specifications Table</a>
                    </li>
                    <li>
                      <a href="/ic#ct">Compatibility Table</a>
                    </li>
                    <li>
                      <a href="/ic#cct">Cost Comparison Table</a>
                    </li>
                    <li>
                      <a href="/ic#sst">Sustainability Table</a>
                    </li>
                    <li>
                      <a href="/ic#at">Applications Table</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <a href="/wws" className={page === "services" ? "active" : ""}>
                Services
              </a>
            </li>
            <li>
              <a
                href="/projects"
                className={page === "projects" ? "active" : ""}
              >
                Projects
              </a>
            </li>

            {/* <li>
              <a
                href="/blog"
                className="<%= activePage === 'blog' ? 'active' : '' %>"
              >
                Blog
              </a>
            </li> */}
            <li>
              <a href="/contact" className={page === "contact" ? "active" : ""}>
                Contact us
              </a>
            </li>
            {/* <% if (user) { %>
        <!-- Content to be displayed if the condition is true -->
        <li style="color: white"><a href="/admin"><%= user.email %></a></li>
        <% } %> <% if (logout) { %>
        <!-- Content to be displayed if the condition is true -->
        <li><a href="/logout" style="color: #ff0123">Logout</a></li>
        <% } %> */}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default View;
