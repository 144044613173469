import React from "react";

import Nav from "./nav";

import Footer from "./footer";

function View() {
  return (
    <>
      <Nav page={"services"}></Nav>

      <main id="main">
        {/* <!-- ======= Breadcrumbs ======= --> */}
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{ backgroundImage: 'url("img/breadcrumbs-bg.jpg")' }}
        >
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h2>Services</h2>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Services</li>
            </ol>
          </div>
        </div>
        {/* <!-- End Breadcrumbs --> */}

        {/* <!-- ======= Services Section ======= --> */}
        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Services</h2>
              <p>Empowering Industries with Comprehensive Casting Solutions</p>
            </div>

            <div className="row gy-4 px-lg-5 mx-lg-3">
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-gear"></i>
                  </div>
                  <h3>Precision Engineering</h3>
                  <p>
                    Our precision engineering services ensure the flawless
                    production of intricate components, tailored to your exact
                    specifications, and designed to optimize performance and
                    efficiency.
                  </p>
                  <a href="/service-d?service=PE" className="readmore">
                    Learn more <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-lightbulb"></i>
                  </div>
                  <h3>Prototyping Solutions</h3>
                  <p>
                    From concept to reality, our prototyping solutions offer
                    fast and accurate representation of your designs, enabling
                    rapid iteration and refinement of your products.
                  </p>
                  <a href="/service-d?service=PS" className="readmore">
                    Learn more <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-tools"></i>
                  </div>
                  <h3>Custom Casting Solutions</h3>
                  <p>
                    Our custom casting services provide flexible and reliable
                    solutions for a wide range of industries, delivering casted
                    components that meet the unique demands of your projects.
                  </p>
                  <a href="/service-d?service=CCS" className="readmore">
                    Learn more <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-thermometer-half"></i>
                  </div>
                  <h3>Material Analysis</h3>
                  <p>
                    Our material analysis services help you select the right
                    materials for your casted components, ensuring durability,
                    performance, and cost-effectiveness.
                  </p>
                  <a href="/service-d?service=MA" className="readmore">
                    Learn more <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-tools"></i>
                  </div>
                  <h3>Machining Services</h3>
                  <p>
                    With state-of-the-art machining capabilities, we offer
                    precision machining services to deliver finished casted
                    components with fine-tuned precision.
                  </p>
                  <a href="/service-d?service=MS" className="readmore">
                    Learn more <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-patch-check"></i>
                  </div>
                  <h3>Quality Inspection</h3>
                  <p>
                    Our rigorous quality inspection processes ensure that each
                    casted component meets the highest standards of quality,
                    guaranteeing reliability and performance.
                  </p>
                  <a href="/service-d?service=QI" className="readmore">
                    Learn more <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>
          </div>
        </section>
        {/* <!-- End Services Section --> */}

        {/* <!-- ======= Servie Cards Section ======= --> */}
        <section id="services-cards" className="services-cards">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4 px-lg-5 mx-lg-3">
              <div
                className="col-lg-3 col-md-6"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <h3>Precision Castings</h3>
                <p>
                  Our investment casting process produces complex and
                  intricately shaped components with exceptional accuracy and
                  surface finish. From aerospace to automotive industries, we
                  deliver precision-crafted castings that meet the highest
                  standards.
                </p>
              </div>
              {/* <!-- End feature item--> */}

              <div
                className="col-lg-3 col-md-6"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <h3>Custom Solutions</h3>
                <p>
                  Emphasizing versatility, we offer custom investment casting
                  solutions tailored to your unique requirements. Our
                  experienced team collaborates closely to understand your needs
                  and deliver castings that fit your specific applications.
                </p>
              </div>
              {/* <!-- End feature item--> */}

              <div
                className="col-lg-3 col-md-6"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <h3>Material Expertise</h3>
                <p>
                  With extensive material knowledge, we cater to diverse
                  industries by selecting the most suitable materials for your
                  casting needs. Our expertise ensures the right material
                  properties for optimal performance.
                </p>
              </div>
              {/* <!-- End feature item--> */}

              <div
                className="col-lg-3 col-md-6"
                data-aos="zoom-in"
                data-aos-delay="400"
              >
                <h3>Efficient Turnaround</h3>
                <p>
                  We understand the importance of time in your projects. With
                  streamlined processes and efficient production, we ensure
                  prompt delivery of investment castings without compromising
                  quality.
                </p>
              </div>
              {/* <!-- End feature item--> */}
            </div>
          </div>
        </section>
        {/* <!-- End Servie Cards Section --> */}
      </main>

      <Footer></Footer>

      <div id="preloader" hidden></div>
    </>
  );
}

export default View;
