import React from "react";

import Nav from "./nav";

import Footer from "./footer";

function View() {
  return (
    <>
      <Nav page={"bod"}></Nav>

      <main id="main">
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{ backgroundImage: "url('img/breadcrumbs-bg.jpg')" }}
        >
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h2>Board of Directors</h2>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Board of Directors</li>
            </ol>
          </div>
        </div>

        <section id="team" className="team">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Our Board</h2>
              <p>The Driving Force Behind Our Success</p>
            </div>

            <div className="row gy-5 px-lg-5 mx-lg-3">
              <div
                className="col-lg-12 col-md-12 member"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="row">
                  <div className="col-lg-6 col-md-6 member">
                    <div className="member-img">
                      <img
                        src="img/team/nana.jpeg"
                        className="img-fluid"
                        alt=""
                      />
                      {/* <!-- <div className="social">
                    <a href="/"><i className="bi bi-twitter"></i></a>
                    <a href="/"><i className="bi bi-facebook"></i></a>
                    <a href="/"><i className="bi bi-instagram"></i></a>
                    <a href="/"><i className="bi bi-linkedin"></i></a>
                  </div> --> */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 member">
                    <div className="member-info text-left">
                      <h4>Hiralal Tilva</h4>
                      <span>Chairman Emeritus & Non-Executive Director</span>
                      <p>
                        Mr. Hiralal Tilva, with his extensive expertise spanning
                        over four decades in various industry domains, possesses
                        invaluable knowledge and experience. Remarkably, he has
                        dedicated 50 years to the forging, casting, pipes, and
                        construction sectors, followed by more than 16 years of
                        specializing in the Forging and Casting industry.
                        <br />
                        <br />
                        Presently, under his visionary leadership, Ganga
                        Technocast has successfully spearheaded expansion and
                        diversification into the casting sector. This
                        diversification has resulted in an impressive casting
                        portfolio, encompassing cutting-edge casting solutions
                        and quality. Notably, these quality and quick delivery
                        have made a significant impact both in India and
                        internationally. <br />
                        <br />
                        Mr. Tilva’s overarching vision revolves around
                        establishing an eminent Indian conglomerate. His
                        aspiration is to provide world-class casting solutions
                        that cater to the needs of merchants, enterprises, and
                        governments on a global scale. By combining his
                        extensive industry experience with Ganga Technocast’s
                        technological prowess, he is determined to create an
                        entity that stands at the forefront of the casting
                        industry, driving innovation and empowering businesses
                        worldwide.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div
                className="col-lg-12 col-md-12 member"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="row">
                  <div className="col-lg-6 col-md-6 member">
                    <div className="member-info text-left">
                      <h4>Shravan Chaniara</h4>
                      <span>Chairman & Managing Director</span>
                      <p>
                        Shravan Chaniara is the Chairman & Managing Director of
                        Ganga Technocast. He shoulders an imperative role in
                        building and scaling a world-className team that designs
                        innovative casting solutions and caters needs of
                        customers globally, with a vision to be a global
                        investment casting manufacturers. <br />
                        <br />
                        His ambition is to nurture Ganga Technocast into a
                        global investment casting manufacturer, wherein as a
                        manufacturer, its role will be to provide best quality
                        and on time delivery; enabling smooth fulfillment of
                        castingn products for all enterprises in India and
                        globally. Shravan founded Ganga Technocast, with
                        determination of making it one of the largest investment
                        casting manufacturers worldwide.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 member">
                    <div className="member-img">
                      <img
                        src="img/team/sc.jpeg"
                        className="img-fluid"
                        alt=""
                      />
                      {/* <!-- <div className="social">
                    <a href="/"><i className="bi bi-twitter"></i></a>
                    <a href="/"><i className="bi bi-facebook"></i></a>
                    <a href="/"><i className="bi bi-instagram"></i></a>
                    <a href="/"><i className="bi bi-linkedin"></i></a>
                  </div> --> */}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div
                className="col-lg-12 col-md-12 member"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="row">
                  <div className="col-lg-6 col-md-6 member">
                    <div className="member-img">
                      <img
                        src="img/team/dk.jpeg"
                        className="img-fluid"
                        alt=""
                      />
                      {/* <!-- <div className="social">
                    <a href="/"><i className="bi bi-twitter"></i></a>
                    <a href="/"><i className="bi bi-facebook"></i></a>
                    <a href="/"><i className="bi bi-instagram"></i></a>
                    <a href="/"><i className="bi bi-linkedin"></i></a>
                  </div> --> */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 member">
                    <div className="member-info text-left">
                      <h4>Divyanshu Kalola</h4>
                      <span>CEO and Joint Managing Director </span>
                      <p>
                        Divyanshu Kalola is the Chief Executive Officer (CEO) of
                        Ganga Technocast, has an exceptional academic
                        background.
                        <br />
                        <br />
                        Having earned an undergraduate degree from the
                        prestigious Illinois Institute of Technology (IIT)
                        Chicago, his educational qualifications are truly
                        exemplary. Such an impressive combination of academic
                        accomplishments undoubtedly equips Divyanshu with a
                        unique and refined insight into the realm of investment
                        casting.
                        <br />
                        <br />
                        With over years of valuable experience in the field of
                        manufacturing , Divyanshu possesses a profound expertise
                        that sets him apart from his peers. Prior to joining the
                        ranks at Ganga Technocast, he had the privilege of
                        contributing his skills and knowledge to the renowned
                        Grainger Inc, a testament to his exceptional
                        capabilities.
                        <br />
                        <br />
                        In conclusion, Divyanshu stands as an incomparable force
                        within the casting industry, with his accomplished
                        educational background and wealth of experience
                        solidifying his position. His invaluable contributions
                        to Ganga Technocast makes him an asset to the
                        organization, as his expertise continues to thrive
                        beneath his capable stewardship.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>

      <a
        href="/"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>

      <div id="preloader" hidden></div>
    </>
  );
}

export default View;
