import React, { useState } from "react";

import Nav from "./nav";

import Footer from "./footer";

import { useForm } from "react-hook-form";
import axios from "axios";

import { db } from "./../firebase-config";
import { collection, addDoc } from "firebase/firestore";

function View() {
  const { register, getValues } = useForm({});
  const [loading, setloading] = useState(false);
  const [finished, setfinished] = useState(false);

  async function submit() {
    setloading(true);
    const edata = {
      service_id: process.env.REACT_APP_SERVICE_ID,
      template_id: "template_z6kej7j",
      user_id: process.env.REACT_APP_USER_ID,
      template_params: {
        reply_to: "info@gangatechnocast.co.in",
        from_name: getValues("name"),
        from_email: getValues("email"),
        from_phone: getValues("phone"),
        from_subject: getValues("subject"),
        from_message: getValues("message"),
        from_tag: "Received - Contact US",
      },
      accessToken: process.env.REACT_APP_ACCESS_TOCKEN,
    };
    const quotesCollection = collection(db, "contact_us");
    try {
      await addDoc(quotesCollection, {
        name: getValues("name"),
        email: getValues("email"),
        phone: getValues("phone"),
        subject: getValues("subject"),
        message: getValues("message"),
        timestamp: Date.now(),
      }).then(() => {
        axios
          .post("https://api.emailjs.com/api/v1.0/email/send", edata)
          .then((response) => {
            console.log("Email sent successfully!", response.data);
            setloading(false);
            setfinished(true);
          })
          .catch((error) => {
            console.error("Error sending email:", error.response.data);
          });
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Nav page={"contact"}></Nav>

      <main id="main">
        {/* <!-- ======= Breadcrumbs ======= --> */}
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{ backgroundImage: 'url("img/breadcrumbs-bg.jpg")' }}
        >
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h2>Contact</h2>
            <ol>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Contact</li>
            </ol>
          </div>
        </div>
        {/* <!-- End Breadcrumbs --> */}

        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4 px-lg-5 mx-lg-3">
              <div className="col-lg-6">
                <div className="info-item d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-map"></i>
                  <h3>Our Address</h3>
                  <p style={{ fontStyle: "italic" }}>
                    Survey No: 61/P3, Village: Bharudi, Tal: Gondal,
                  </p>
                  <p style={{ fontStyle: "italic" }}>
                    Rajkot, Gujarat 360311, India
                  </p>
                </div>
              </div>
              {/* <!-- End Info Item --> */}

              <div className="col-lg-3 col-md-6">
                <div className="info-item d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>
                    <a
                      href="mailto:info@gangatechnocast.co.in"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@gangatechnocast.co.in
                    </a>
                  </p>
                  <p>
                    <br />
                  </p>
                </div>
              </div>
              {/* <!-- End Info Item --> */}

              <div className="col-lg-3 col-md-6">
                <div className="info-item d-flex flex-column justify-content-center align-items-center">
                  <i className="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p>
                    <a href="tel:+919426346460">+91 9426346460</a>
                  </p>
                </div>
              </div>
              {/* <!-- End Info Item --> */}
            </div>

            <div className="row gy-4 mt-1 px-lg-5 mx-lg-3">
              <div className="col-lg-6">
                <iframe
                  title="Maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.831172075031!2d70.78193547607752!3d22.284384243470665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959cb065cfcfc35%3A0x4659dc2b952e8c09!2sMegh%20Malhar%20Apartment!5e0!3m2!1sen!2sus!4v1690134579558!5m2!1sen!2sus"
                  frameborder="0"
                  style={{ border: 0, width: "100%", height: "384px" }}
                  allowfullscreen
                ></iframe>
              </div>
              {/* <!-- End Google Maps --> */}

              <div className="col-lg-6">
                <form id="contact_us" className="php-email-form">
                  <div className="row gy-4">
                    <div className="col-lg-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        {...register("name")}
                        required
                      />
                    </div>
                    <div className="col-lg-6 form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        id="phone"
                        placeholder="Your Phone"
                        {...register("phone")}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      {...register("email")}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      {...register("subject")}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="3"
                      placeholder="Message"
                      {...register("message")}
                      required
                    ></textarea>
                  </div>

                  <div className="col-md-12 text-center">
                    <div
                      className="loading"
                      style={{ display: loading ? "block" : "none" }}
                    >
                      Loading
                    </div>
                    <div className="error-message" hidden></div>
                    <div
                      className="sent-message"
                      style={{ display: finished ? "block" : "none" }}
                    >
                      Your message has been recorded. Thank you for contacting
                      Ganga technocast!
                    </div>

                    {!loading ? (
                      <button
                        style={{
                          background: "transparent",
                          border: "transparent",
                          fontStyle: "italic",
                          color: "red",
                        }}
                        type="button"
                        onClick={submit}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        style={{
                          background: "transparent",
                          border: "transparent",
                          fontStyle: "italic",
                          color: "red",
                        }}
                        type="button"
                      >
                        Loading ...
                      </button>
                    )}
                  </div>
                </form>
              </div>
              {/* <!-- End Contact Form --> */}
            </div>
          </div>
        </section>
        {/* <!-- End Contact Section --> */}
      </main>
      <Footer></Footer>

      <a
        href="#main"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>

      <div id="preloader" hidden></div>
    </>
  );
}

export default View;
