import React, { useEffect, useState } from "react";

import Nav from "./nav";

import Footer from "./footer";

import { listAll, ref, getMetadata, getDownloadURL } from "firebase/storage";

import { storage } from "./../firebase-config";

function Work() {
  const [URLS, setURLS] = useState([]);
  const [OURLS, setOURLS] = useState([]);
  const [tag, settag] = useState("");
  useEffect(() => {
    async function getUrls(path) {
      const listRef = ref(storage, path);
      const URLS = [];
      const tags = [];

      try {
        const res = await listAll(listRef);

        const promises = res.items.map(async (itemRef) => {
          try {
            const metadata = await getMetadata(ref(storage, itemRef.fullPath));
            const url = await getDownloadURL(ref(storage, itemRef.fullPath));
            tags.push(
              metadata.customMetadata !== undefined
                ? metadata.customMetadata.tag
                : "All"
            );
            URLS.push({
              url: url,
              path: itemRef.fullPath.split("/")[1],
              metadata:
                metadata.customMetadata !== undefined
                  ? metadata.customMetadata.tag
                  : "null",
            });
          } catch (error) {
            console.log(error);
          }
        });

        // Wait for all the promises to resolve
        await Promise.all(promises);

        const returnData = [URLS, Array.from(new Set(tags))];

        return returnData;
      } catch (error) {
        console.log(error);
        const returnData = [URLS, Array.from(new Set(tags))];
        return returnData;
      }
    }

    getUrls("Gallery").then((data) => {
      console.log(data);
      setURLS(data);
      setOURLS(data);
    });
  }, []);

  useEffect(() => {
    const updated = filterArrayByMetadata(URLS, tag);
    setURLS(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  function filterArrayByMetadata(array, metadata) {
    if (
      !Array.isArray(array) ||
      array.length !== 2 ||
      !Array.isArray(array[0])
    ) {
      // Ensure that the input array has the expected structure
      return [];
    }

    if (metadata === "All") {
      // If metadata is "All", return the original array
      return OURLS;
    }

    const filteredArray = array[0].filter((item) => item.metadata === metadata);

    return [filteredArray, array[1]];
  }
  return (
    <>
      <Nav page={"home"}></Nav>
      <section id="hero" className="hero">
        <div className="info d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 text-center">
                <h2 data-aos="fade-down">
                  Welcome to <span>Ganga Technocast</span>
                </h2>
                <p data-aos="fade-up">
                  Your trusted partner in investment casting. We bring fresh
                  perspectives and a passion for excellence to the industry. Our
                  dedicated team is committed to delivering high-quality
                  castings that meet your unique requirements.
                </p>
                <a
                  data-aos="fade-up"
                  data-aos-delay="200"
                  href="#get-started"
                  className="btn-get-started"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          id="hero-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="5000"
        >
          <div
            className="carousel-item active"
            style={{ backgroundImage: "url(/img/local/home1.jpeg)" }}
          ></div>
          <div
            className="carousel-item"
            style={{ backgroundImage: "url(/img/local/home2.jpg)" }}
          ></div>
          <div
            className="carousel-item"
            style={{ backgroundImage: "url(/img/local/home3.jpeg)" }}
          ></div>
          <div
            className="carousel-item"
            style={{ backgroundImage: "url(/img/local/home4.jpeg)" }}
          ></div>
          <div
            className="carousel-item"
            style={{ backgroundImage: "url(/img/local/home5.jpeg)" }}
          ></div>

          <a
            className="carousel-control-prev"
            href="#hero-carousel"
            role="button"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon bi bi-chevron-left"
              aria-hidden="true"
            ></span>
          </a>

          <a
            className="carousel-control-next"
            href="#hero-carousel"
            role="button"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon bi bi-chevron-right"
              aria-hidden="true"
            ></span>
          </a>
        </div>
      </section>

      <main id="main">
        <section id="get-started" className="get-started section-bg">
          <div className="container">
            <div className="row px-lg-5 mx-lg-3 justify-content-between gy-4">
              <div
                className="col-lg-6 d-flex align-items-center"
                data-aos="fade-up"
              >
                <div className="content">
                  <h3>Our Commitment to Excellence</h3>
                  <p className="fst-italic">
                    Delivering Precision Castings and Unparalleled Service
                  </p>
                  <ul>
                    <li>
                      We leverage cutting-edge technology to enhance the casting
                      process.
                    </li>
                    <li>
                      Our strict quality control measures ensure flawless
                      castings.
                    </li>
                    <li>
                      We are committed to delivering projects on time, without
                      compromising quality.
                    </li>
                    <li>
                      Our knowledgeable team provides expert guidance and
                      consultation throughout the process.
                    </li>
                    <li>
                      We work closely with clients, fostering collaborative
                      relationships for successful outcomes.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-5" data-aos="fade">
                <form
                  id="quote"
                  className="php-email-form"
                  action="/submit-quote"
                  method="post"
                >
                  <h3>Get your quote today</h3>
                  <p>
                    Get in touch with us and experience the seamless execution
                    of your business goals.
                  </p>
                  <div className="row gy-3">
                    <div className="col-md-12">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email"
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <div className="row">
                        <div className="col">
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            placeholder="Phone"
                            required
                          />
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className="form-control"
                            name="material"
                            placeholder="Material"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="6"
                        placeholder="Message"
                        required
                      ></textarea>
                    </div>

                    <div className="col-md-12 text-center">
                      <div className="loading">Loading</div>
                      <div className="error-message" hidden></div>
                      <div className="sent-message">
                        Your quote request has been sent successfully. Thank
                        you!
                      </div>

                      <button type="submit">Get a quote</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section id="constructions" className="constructions">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Our Expertise</h2>
              <p>Empowering Your Vision with Exceptional Casting Solutions</p>
            </div>

            <div className="row px-lg-5 mx-lg-3 gy-4">
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div className="card-item">
                  <div className="row">
                    <div className="col-xl-5">
                      <div
                        className="card-bg"
                        style={{
                          backgroundImage:
                            "url(https://sinowayindustry.com/wp-content/uploads/2021/01/illustration-lost-wax-investment-casting.jpg)",
                        }}
                      ></div>
                    </div>
                    <div className="col-xl-7 d-flex align-items-center">
                      <div className="card-body">
                        <h4 className="card-title">
                          Precision Casting Solutions
                        </h4>
                        <p>
                          Our precision casting expertise ensures that every
                          component is crafted with exceptional accuracy and
                          quality, meeting the most demanding requirements of
                          your projects.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <div className="card-item">
                  <div className="row">
                    <div className="col-xl-5">
                      <div
                        className="card-bg"
                        style={{
                          backgroundImage:
                            "url(https://www.reliance-foundry.com/wp-content/uploads/Cast-iron-parts.jpg)",
                        }}
                      ></div>
                    </div>
                    <div className="col-xl-7 d-flex align-items-center">
                      <div className="card-body">
                        <h4 className="card-title">Diverse Applications</h4>
                        <p>
                          From automotive and aerospace to industrial and
                          consumer goods, our casting solutions cater to a wide
                          range of industries, enabling innovation and
                          excellence in every field.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                <div className="card-item">
                  <div className="row">
                    <div className="col-xl-5">
                      <div
                        className="card-bg"
                        style={{
                          backgroundImage:
                            " url(https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Aerial_photograph_of_Globalfoundries_Dresden.jpg/1200px-Aerial_photograph_of_Globalfoundries_Dresden.jpg)",
                        }}
                      ></div>
                    </div>
                    <div className="col-xl-7 d-flex align-items-center">
                      <div className="card-body">
                        <h4 className="card-title">Cutting-Edge Technology</h4>
                        <p>
                          Equipped with advanced technology and machinery, our
                          foundry facility enables us to stay at the forefront
                          of casting innovation, delivering top-notch results
                          for your projects.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                <div className="card-item">
                  <div className="row">
                    <div className="col-xl-5">
                      <div
                        className="card-bg"
                        style={{
                          backgroundImage:
                            "url(https://www.reliance-foundry.com/wp-content/uploads/quality-casting-ultrasound-1.jpg)",
                        }}
                      ></div>
                    </div>
                    <div className="col-xl-7 d-flex align-items-center">
                      <div className="card-body">
                        <h4 className="card-title">Quality Assurance</h4>
                        <p>
                          Our meticulous quality control process ensures that
                          each casted component undergoes rigorous inspection,
                          guaranteeing the highest standards of performance and
                          reliability.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Services</h2>
              <p>Empowering Industries with Comprehensive Casting Solutions</p>
            </div>

            <div className="row px-lg-5 mx-lg-3 gy-4">
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-gear"></i>
                  </div>
                  <h3>Precision Engineering</h3>
                  <p>
                    Our precision engineering services ensure the flawless
                    production of intricate components, tailored to your exact
                    specifications, and designed to optimize performance and
                    efficiency.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-lightbulb"></i>
                  </div>
                  <h3>Prototyping Solutions</h3>
                  <p>
                    From concept to reality, our prototyping solutions offer
                    fast and accurate representation of your designs, enabling
                    rapid iteration and refinement of your products.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-tools"></i>
                  </div>
                  <h3>Custom Casting Solutions</h3>
                  <p>
                    Our custom casting services provide flexible and reliable
                    solutions for a wide range of industries, delivering casted
                    components that meet the unique demands of your projects.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-thermometer-half"></i>
                  </div>
                  <h3>Material Analysis</h3>
                  <p>
                    Our material analysis services help you select the right
                    materials for your casted components, ensuring durability,
                    performance, and cost-effectiveness.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-tools"></i>
                  </div>
                  <h3>Machining Services</h3>
                  <p>
                    With state-of-the-art machining capabilities, we offer
                    precision machining services to deliver finished casted
                    components with fine-tuned precision.
                  </p>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="service-item position-relative">
                  <div className="icon">
                    <i className="fa-solid bi bi-patch-check"></i>
                  </div>
                  <h3>Quality Inspection</h3>
                  <p>
                    Our rigorous quality inspection processes ensure that each
                    casted component meets the highest standards of quality,
                    guaranteeing reliability and performance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="alt-services" className="alt-services">
          <div className="container" data-aos="fade-up">
            <div className="row px-lg-5 mx-lg-3 justify-content-around gy-4">
              <div
                className="col-lg-6 img-bg"
                style={{ backgroundImage: "url(/img/alt-services.jpg)" }}
                data-aos="zoom-in"
                data-aos-delay="100"
              ></div>

              <div className="col-lg-5 d-flex flex-column justify-content-center">
                <h3>Precision Casting Solutions</h3>
                <p>
                  Our precision casting solutions are designed to meet the
                  unique needs of your projects. From small intricate components
                  to large complex structures, we excel in delivering precise
                  and reliable castings.
                </p>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i className="bi bi-gem flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>Material Selection</a>
                    </h4>
                    <p>
                      Our experts guide you in selecting the most suitable
                      materials for your casting requirements, considering
                      factors like durability, performance, and
                      cost-effectiveness.
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i className="bi bi-gear flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>Advanced Technology</a>
                    </h4>
                    <p>
                      We utilize state-of-the-art technology and advanced
                      machinery to ensure optimal casting quality, efficiency,
                      and adherence to strict tolerances.
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i className="bi bi-hammer flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>Skilled Craftsmanship</a>
                    </h4>
                    <p>
                      Our team of experienced craftsmen brings precision and
                      artistry to every casting, meticulously crafting each
                      component with attention to detail.
                    </p>
                  </div>
                </div>

                <div
                  className="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i className="bi bi-check-circle flex-shrink-0"></i>
                  <div>
                    <h4>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a>Quality Assurance</a>
                    </h4>
                    <p>
                      Our rigorous quality control process involves thorough
                      inspections at every stage, ensuring that the final
                      castings meet the highest standards of quality and
                      performance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="features section-bg">
          <div className="container" data-aos="fade-up">
            <ul className="nav nav-tabs row px-lg-5 mx-lg-3 g-2 d-flex">
              <li className="nav-item col-3">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="nav-link active show"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-1"
                >
                  <h4>Quality Excellence</h4>
                </a>
              </li>

              <li className="nav-item col-3">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-2"
                >
                  <h4>Diverse Expertise</h4>{" "}
                </a>
              </li>

              <li className="nav-item col-3">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-3"
                >
                  <h4>Flexible Customization</h4>
                </a>
              </li>

              <li className="nav-item col-3">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-4"
                >
                  <h4>Timely Delivery</h4>
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div className="tab-pane active show" id="tab-1">
                <div className="row px-lg-5 mx-lg-3">
                  <div
                    className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <h3>Precision Craftsmanship & Stringent Inspections</h3>
                    <p className="fst-italic">
                      Our commitment to quality excellence is reflected in our
                      precision craftsmanship and meticulous inspections at
                      every stage of the casting process. Each component
                      undergoes rigorous checks, ensuring it meets the highest
                      industry standards before reaching your hands.
                    </p>
                    <ul>
                      <li>
                        <i className="bi bi-check2-all"></i> Advanced Technology
                        and Machinery
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> Skilled and
                        Experienced Craftsmen
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> Stringent Quality
                        Control Measures
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> Consistent
                        Performance and Durability
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-lg-6 order-1 order-lg-2 text-center"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <img
                      src="/img/features-1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="tab-2">
                <div className="row px-lg-5 mx-lg-3">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                    <h3>Serving a Wide Range of Industries</h3>
                    <p className="fst-italic">
                      With our diverse expertise, we cater to an array of
                      industries, offering casting solutions that empower
                      businesses across various sectors. From automotive and
                      aerospace to energy and consumer goods, our capabilities
                      extend to meet the unique demands of every industry.
                    </p>
                    <ul>
                      <li>
                        <i className="bi bi-check2-all"></i> Automotive and
                        Transportation
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> Aerospace and
                        Defense
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> Energy and Power
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> Industrial
                        Machinery
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img
                      src="/img/features-2.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="tab-3">
                <div className="row px-lg-5 mx-lg-3">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                    <h3>Tailored Solutions for Your Vision</h3>
                    <ul>
                      <li>
                        <i className="bi bi-check2-all"></i> Collaborative
                        Design and Development
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> Material Selection
                        Assistance
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> Prototype Casting
                        for Evaluation
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> Flexible Production
                        Quantities
                      </li>
                    </ul>
                    <p className="fst-italic">
                      We understand that each project is unique. Our team works
                      closely with you to understand your specific requirements,
                      providing customized casting solutions that align
                      perfectly with your vision, enhancing the performance of
                      your products.
                    </p>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img
                      src="/img/features-3.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="tab-4">
                <div className="row px-lg-5 mx-lg-3">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                    <h3>Efficient Turnaround for Your Success</h3>
                    <p className="fst-italic">
                      Time is of the essence, and we pride ourselves on timely
                      deliveries to ensure your projects stay on track. With
                      efficient production processes and prompt shipping, we
                      guarantee that you receive your casted components as per
                      your schedule.
                    </p>
                    <ul>
                      <li>
                        <i className="bi bi-check2-all"></i> Fast Prototyping
                        and Sampling
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> treamlined
                        Production Processes
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> On-Time Shipping
                        and Delivery
                      </li>
                      <li>
                        <i className="bi bi-check2-all"></i> Commitment to
                        Deadlines
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img
                      src="/img/features-4.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="projects" className="projects">
          <div className="container" data-aos="fade-up">
            <div
              className="portfolio-isotope px-lg-5 mx-lg-3"
              data-portfolio-filter="*"
              data-portfolio-layout="masonry"
              data-portfolio-sort="original-order"
            >
              <ul
                className="portfolio-flters"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <li
                  onClick={() => {
                    settag("All");
                  }}
                  className="filter-active"
                >
                  All
                </li>

                {URLS[1]
                  ? URLS[1].map((tag) => {
                      if (tag !== "All") {
                        // If tag is not "All", return null or an empty fragment
                        return null;
                      }
                      return (
                        <li
                          key={tag} // Add a unique key to each list item
                          onClick={() => {
                            settag(tag);
                          }}
                        >
                          {tag}
                        </li>
                      );
                    })
                  : null}
              </ul>
              {/* <!-- End Projects Filters --> */}
              <div
                className="row gy-4 portfolio-container"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {URLS[0]
                  ? URLS[0].map((url) => {
                      console.log(url);
                      return (
                        <div className={`col-lg-4 col-md-6 portfolio-item`}>
                          <div className="portfolio-content h-100">
                            <img src={url.url} className="img-fluid" alt="" />
                            <div className="portfolio-info">
                              <h4>{url.path}</h4>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              {/* <!-- End Projects Container --> */}
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
}

export default Work;
