import React from "react";

const ExternalRedirectComponent = () => {
  return (
    <div
      className="row d-flex justify-content-center align-items-center text-center"
      style={{ height: "100vh" }}
    >
      <div className="col-6">
        <p>
          Please click the button to open admin page. The page might take a
          while to load as the admin server has to start up.
        </p>
        <a
          type="button"
          href="https://gangatechnocast-2gm5.onrender.com/admin"
          className="btn btn-primary"
          target="_blank"
          rel="noreferrer"
        >
          Redirect to External URL
        </a>
      </div>
    </div>
  );
};

export default ExternalRedirectComponent;
