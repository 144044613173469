import React, { useEffect, useState } from "react";

import Nav from "./nav";

import Footer from "./footer";

import { useSearchParams, Link } from "react-router-dom";

function View() {
  const [searchParams] = useSearchParams();
  const [choice, setchoice] = useState("");
  const [service, setservice] = useState({
    id: "PE",
    title: "Precision Engineering",
    img: "https://cdn.thomasnet.com/insights-images/embedded-images/ba20fdcd-10da-4764-b609-7f3507d2bb60/f7cef038-a108-404f-8212-c2f82adc16ea/Medium/modern-machining-tools-min.jpg",
    body: [
      "Our Precision Engineering services combine cutting-edge technology and expert craftsmanship to deliver components with exceptional accuracy and performance. From concept to production, we ensure seamless execution and offer tailored solutions for even the most complex engineering challenges.",
      "With a focus on precision, our engineering team utilizes advanced tools and techniques to transform ideas into reality. We take pride in delivering high-quality components that meet and exceed industry standards.",
    ],
  });
  const myParam = searchParams.get("service");

  const services = [
    {
      id: "PE",
      title: "Precision Engineering",
      img: "https://cdn.thomasnet.com/insights-images/embedded-images/ba20fdcd-10da-4764-b609-7f3507d2bb60/f7cef038-a108-404f-8212-c2f82adc16ea/Medium/modern-machining-tools-min.jpg",
      body: [
        "Our Precision Engineering services combine cutting-edge technology and expert craftsmanship to deliver components with exceptional accuracy and performance. From concept to production, we ensure seamless execution and offer tailored solutions for even the most complex engineering challenges.",
        "With a focus on precision, our engineering team utilizes advanced tools and techniques to transform ideas into reality. We take pride in delivering high-quality components that meet and exceed industry standards.",
      ],
    },
    {
      id: "PS",
      title: "Prototyping Solutions",
      img: "https://www.automation.com/getmedia/f9449c59-f021-4b9d-95fe-f9940a1590f8/PAE178---Image",
      body: [
        "Embrace innovation with our Prototyping Solutions, where ideas come to life. Our rapid prototyping techniques allow quick materialization of concepts, providing functional prototypes for testing and validation.",
        "From design refinement to the final iteration, we guide you through the journey of turning ideas into reality, ensuring your products are ready to perform in the real world.",
      ],
    },
    {
      id: "CCS",
      title: "Custom Casting Solutions",
      img: "https://www.pentzcastsolutions.com/uploads/images/all_photos/Vibratory%20Polished%20aluminum%20castings.jpg",
      body: [
        "Our Custom Casting Solutions offer a diverse range of casting processes tailored to your specific requirements. Whether it's investment casting, sand casting, or die casting, we have the expertise to provide efficient and cost-effective solutions.",
        "With an emphasis on quality and precision, we produce castings that meet the highest industry standards. Let our experienced team bring your designs to life through our advanced casting capabilities.",
      ],
    },
    {
      id: "MA",
      title: "Material Analysis",
      img: "https://mc-68095c24-9d47-44d2-a4ee-620361-cdn-endpoint.azureedge.net/-/media/images/services/materials-testing/chemical-analysis-preparation-640x480.jpg?rev=9fd0ba28146142b890b1e070ff7cd819",
      body: [
        "Unlock the full potential of your materials with our Material Analysis services. Through rigorous testing and analysis, we help you understand the characteristics and behavior of your materials under different conditions.",
        "Our state-of-the-art laboratory and experienced team ensure accurate results and valuable insights, enabling you to make informed decisions for your projects and applications.",
      ],
    },
    {
      id: "MS",
      title: "Machining Services",
      img: "https://m.holzherusa.com/fileadmin/_processed_/1/7/csm_img_09261_6aef26bbfa.jpg",
      body: [
        "Experience precision machining with our Machining Services. From simple components to complex parts, we utilize advanced CNC machines and skilled operators to achieve tight tolerances and impeccable finishes.",
        "Our commitment to delivering excellence in machining means you can rely on us for high-quality products that meet your exact specifications, every time.",
      ],
    },
    {
      id: "QI",
      title: "Quality Inspection",
      img: "https://www.compliancequest.com/wp-content/uploads/2023/06/quality-control-inspection-video.jpg",
      body: [
        "At Ganga Technocast, we prioritize quality through our comprehensive Quality Inspection services. Our dedicated team uses advanced inspection equipment to ensure each component undergoes rigorous checks for accuracy and adherence to standards.",
        "With our commitment to quality assurance, we guarantee the utmost reliability and consistency in every casting and manufacturing process.",
      ],
    },
  ];

  const getServiceById = (id) => {
    return services.find((service) => service.id === id);
  };

  useEffect(() => {
    setchoice(myParam);
    setservice(getServiceById(myParam));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myParam]);
  return (
    <>
      <Nav page={"services"}></Nav>

      <main id="main">
        {/* <!-- ======= Breadcrumbs ======= --> */}
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{ backgroundImage: 'url("img/breadcrumbs-bg.jpg")' }}
        >
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h2>Service Details</h2>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Service Details</li>
            </ol>
          </div>
        </div>
        {/* <!-- End Breadcrumbs --> */}

        {/* <!-- ======= Service Details Section ======= --> */}
        <section id="service-details" className="service-details">
          <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4 px-lg-5 mx-lg-3">
              <div className="col-lg-4">
                <div className="services-list">
                  <Link
                    to={{
                      pathname: "/service-d",
                      search: "?service=PE",
                    }}
                    className={choice === "PE" ? "active" : ""}
                  >
                    Precision Engineering
                  </Link>
                  <Link
                    to={{
                      pathname: "/service-d",
                      search: "?service=PS",
                    }}
                    className={choice === "PS" ? "active" : ""}
                  >
                    Prototyping Solutions
                  </Link>
                  <Link
                    to={{
                      pathname: "/service-d",
                      search: "?service=CCS",
                    }}
                    className={choice === "CCS" ? "active" : ""}
                  >
                    Custom Casting Solutions
                  </Link>
                  <Link
                    to={{
                      pathname: "/service-d",
                      search: "?service=MA",
                    }}
                    className={choice === "MA" ? "active" : ""}
                  >
                    Material Analysis
                  </Link>
                  <Link
                    to={{
                      pathname: "/service-d",
                      search: "?service=MS",
                    }}
                    className={choice === "MS" ? "active" : ""}
                  >
                    Machining Services
                  </Link>
                  <Link
                    to={{
                      pathname: "/service-d",
                      search: "?service=QI",
                    }}
                    className={choice === "QI" ? "active" : ""}
                  >
                    Quality Inspection
                  </Link>
                </div>
              </div>

              <div className="col-lg-8">
                <img
                  src={service.img}
                  alt=""
                  className="img-fluid services-img"
                />
                <h3>{service.title}</h3>
                {service.body.map((para) => {
                  return <p>{para}</p>;
                })}
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Service Details Section --> */}
      </main>

      <Footer></Footer>

      <div id="preloader" hidden></div>
    </>
  );
}

export default View;
