import React, { useEffect, useState, useRef } from "react";

import Nav from "./nav";

import Footer from "./footer";

import { listAll, ref, getMetadata, getDownloadURL } from "firebase/storage";

import { storage } from "./../firebase-config";

function View() {
  const loading = useRef(null);
  const [URLS, setURLS] = useState([]);
  const [OURLS, setOURLS] = useState([]);
  const [tag, settag] = useState("");
  useEffect(() => {
    async function getUrls(path) {
      const listRef = ref(storage, path);
      const URLS = [];
      const tags = [];

      try {
        const res = await listAll(listRef);

        const promises = res.items.map(async (itemRef) => {
          try {
            const metadata = await getMetadata(ref(storage, itemRef.fullPath));
            const url = await getDownloadURL(ref(storage, itemRef.fullPath));
            tags.push(
              metadata.customMetadata !== undefined
                ? metadata.customMetadata.tag
                : "All"
            );
            URLS.push({
              url: url,
              path: itemRef.fullPath.split("/")[1],
              metadata:
                metadata.customMetadata !== undefined
                  ? metadata.customMetadata.tag
                  : "null",
            });
          } catch (error) {
            console.log(error);
          }
        });

        // Wait for all the promises to resolve
        await Promise.all(promises);

        const returnData = [URLS, Array.from(new Set(tags))];

        return returnData;
      } catch (error) {
        console.log(error);
        const returnData = [URLS, Array.from(new Set(tags))];
        return returnData;
      }
    }
    const preloaderElement = loading.current;

    // Show the preloader
    preloaderElement.hidden = false;
    getUrls("Gallery").then((data) => {
      const preloaderElement = loading.current;
      preloaderElement.hidden = true;
      setURLS(data);
      setOURLS(data);
    });
  }, []);

  useEffect(() => {
    const updated = filterArrayByMetadata(URLS, tag);
    setURLS(updated);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  function filterArrayByMetadata(array, metadata) {
    if (
      !Array.isArray(array) ||
      array.length !== 2 ||
      !Array.isArray(array[0])
    ) {
      // Ensure that the input array has the expected structure
      return [];
    }

    if (metadata === "All") {
      // If metadata is "All", return the original array
      return OURLS;
    }

    const filteredArray = array[0].filter((item) => item.metadata === metadata);

    return [filteredArray, array[1]];
  }
  return (
    <>
      <Nav page={"projects"}></Nav>

      <main id="main">
        {/* <!-- ======= Breadcrumbs ======= --> */}
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{ backgroundImage: 'url("img/breadcrumbs-bg.jpg")' }}
        >
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h2>Projects</h2>
            <ol>
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>Projects</li>
            </ol>
          </div>
        </div>
        {/* <!-- End Breadcrumbs --> */}

        {/* <!-- ======= Our Projects Section ======= --> */}
        <section id="projects" className="projects">
          <div className="container" data-aos="fade-up">
            <div
              className="portfolio-isotope px-lg-5 mx-lg-3"
              data-portfolio-filter="*"
              data-portfolio-layout="masonry"
              data-portfolio-sort="original-order"
            >
              <ul
                className="portfolio-flters"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <li
                  onClick={() => {
                    settag("All");
                  }}
                  className="filter-active"
                >
                  All
                </li>

                {URLS[1]
                  ? URLS[1].map((tag) => {
                      if (tag !== "All") {
                        // If tag is not "All", return null or an empty fragment
                        return null;
                      }
                      return (
                        <li
                          key={tag} // Add a unique key to each list item
                          onClick={() => {
                            settag(tag);
                          }}
                        >
                          {tag}
                        </li>
                      );
                    })
                  : null}
              </ul>
              {/* <!-- End Projects Filters --> */}
              <div
                className="row gy-4 portfolio-container"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {URLS[0]
                  ? URLS[0].map((url) => {
                      console.log(url);
                      return (
                        <div className={`col-lg-4 col-md-6 portfolio-item`}>
                          <div className="portfolio-content h-100">
                            <img src={url.url} className="img-fluid" alt="" />
                            <div className="portfolio-info">
                              <h4>{url.path}</h4>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              {/* <!-- End Projects Container --> */}
            </div>
          </div>
        </section>
        {/* <!-- End Our Projects Section --> */}
      </main>

      <Footer></Footer>

      <div id="preloader" ref={loading} hidden></div>
    </>
  );
}

export default View;
