import React from "react";

import Nav from "./nav";

import Footer from "./footer";

function View() {
  return (
    <>
      <Nav page={"kp"}></Nav>

      <main id="main">
        <div
          className="breadcrumbs d-flex align-items-center"
          style={{ backgroundImage: ' url("img/breadcrumbs-bg.jpg")' }}
        >
          <div
            className="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h2>Key Personnel</h2>
            <ol>
              <li>
                <a href="/">Home</a>
              </li>
              <li>Key Personnel</li>
            </ol>
          </div>
        </div>

        <section id="team" className="team">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Our Staff</h2>
              <p>The Driving Force Behind Our Success</p>
            </div>

            <div className="row gy-5 px-lg-5 mx-lg-3">
              <div
                className="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="member-img">
                  <img src="img/team/team-1.jpg" className="img-fluid" alt="" />
                  {/* <!-- <div className="social">
                    <a href="#"><i className="bi bi-twitter"></i></a>
                    <a href="#"><i className="bi bi-facebook"></i></a>
                    <a href="#"><i className="bi bi-instagram"></i></a>
                    <a href="#"><i className="bi bi-linkedin"></i></a>
                  </div> --> */}
                </div>

                <div className="member-info text-center">
                  <h4>Hiralal Tilva</h4>
                  <span>Chairman Emeritus & Non-Executive Director</span>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="member-img">
                  <img src="img/team/team-1.jpg" className="img-fluid" alt="" />
                  {/* <!-- <div className="social">
                    <a href="#"><i className="bi bi-twitter"></i></a>
                    <a href="#"><i className="bi bi-facebook"></i></a>
                    <a href="#"><i className="bi bi-instagram"></i></a>
                    <a href="#"><i className="bi bi-linkedin"></i></a>
                  </div> --> */}
                </div>

                <div className="member-info text-center">
                  <h4>Hiralal Tilva</h4>
                  <span>Chairman Emeritus & Non-Executive Director</span>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 member"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="member-img">
                  <img src="img/team/team-1.jpg" className="img-fluid" alt="" />
                  {/* <!-- <div className="social">
                    <a href="#"><i className="bi bi-twitter"></i></a>
                    <a href="#"><i className="bi bi-facebook"></i></a>
                    <a href="#"><i className="bi bi-instagram"></i></a>
                    <a href="#"><i className="bi bi-linkedin"></i></a>
                  </div> --> */}
                </div>

                <div className="member-info text-center">
                  <h4>Hiralal Tilva</h4>
                  <span>Chairman Emeritus & Non-Executive Director</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer></Footer>

      <div id="preloader" hidden></div>
    </>
  );
}

export default View;
